import React from 'react';
import Slider from 'react-slick';
import "./TabComponent.css"
import { BaseColor } from '../../config';

function TabComponent(props) {

    const { classes, tabs, textTransform,IsApiData, IsAdminAPIData,setTab, fontSize, fontWeight } = props;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        nav: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    variableWidth: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    variableWidth: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: true
                }
            }
        ]
    }
    return (
        <div className={classes.root}>
            <ul>
                <Slider {...settings}>
                    {tabs?.map((obj, index) => {
                        return <div style={{ display: 'flex' }}>
                            <li key={index} onClick={() => setTab(index, IsApiData ? IsAdminAPIData ? obj.SequenceNo : obj.SequenceNo - 1 : obj.ID)} >
                                <a title={obj.Name} style={{ cursor: 'pointer', textTransform: textTransform, fontSize: fontSize, fontWeight: fontWeight }} className={`${props.activeTab === index ? 'active' : ''}`} >{obj.Name}</a>
                                {tabs.length != index + 1 && <div style={{ color: BaseColor.grayLightColor }}>&nbsp;&nbsp;&nbsp;|&nbsp;</div>}
                            </li>
                        </div>
                    })}
                </Slider>
            </ul>
        </div>
    )
    {/* <span className="tab-arrow"></span> */ }
}

export default TabComponent;