import React from 'react';
import './DotPagination.css';
import PropTypes from "prop-types";

const DotPagination = (props) => {
  const { PaginationData, onClick, ActivePage } = props
  return (
    <div className="DotPagination-slider-container">
      <div className="DotPagination-pagination">
        {PaginationData?.length > 1 && PaginationData?.map((_, index) => (
          <button
            key={index}
            className={index === ActivePage ? 'active' : ''}
            onClick={onClick}
            aria-label={`Go to slide ${index + 1}`}>
          </button>
        ))}
      </div>
    </div>
  );
};
DotPagination.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  PaginationData: PropTypes.array,
  onClick: PropTypes.func,
  ActivePage: PropTypes.number
};
DotPagination.defaultProps = {
  customClass: {},
  onClick: () => { },
  PaginationData: [],
  ActivePage: 0

};
export default DotPagination;
