
import React, { useState } from 'react';
import './FileUpload.css'; // Import your CSS file for styling
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";


function FileUpload(props) {
    const [FileName, setFileName] = useState('');


    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <div className="form_field">
            <input id="fileupload" name="files" type="file" accept=".html"
                onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type === "text/html") {
                        setFileName(file.name)
                        getBase64(file)
                            .then(result => {
                                file["base64"] = result;
                                props.BaseHTMLData(result.split(',').pop())
                            }).catch(err => { });

                        const reader = new window.FileReader();
                        reader.onload = (data) => { props.HTMLData(data.target.result) };
                        reader.readAsText(file);
                    }
                }} />

            {FileName == ""
                ? <label htmlFor="fileupload" className="input-file-trigger">
                    <img src={file_upload_ic} alt="file-uplaod" /> Drag and drop your file here
                </label>
                : <label htmlFor="fileupload" className="input-file-trigger">
                    {FileName}
                </label>}
        </div>
    );
}

export default FileUpload;