import React from 'react';
import './singaleRadioButton.css';

export default function SingaleRadioButton(props) {
    const { id, checked, onChange, label, name, IslabelShow, disabled } = props
    return (
        <div className="form_field field-right custom-radio-singale">
            {IslabelShow == false
                ? <div style={{ paddingLeft: '40px' }}></div>
                : <input
                    id={id}
                    disabled={disabled}
                    name={name}
                    type="radio"
                    checked={checked}
                    onChange={onChange}
                />}

            <label htmlFor={`${id}`} style={checked ? { fontWeight: 600 } : { fontWeight: 400 }}>{label}</label>
        </div>
    )
}