import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { BaseColor, FontFamily, FontWeight } from "../../config";
import { IsEmpty } from "../../config/appConstant";

const CssDropdown = withStyles({
  root: {
    "& label": {
      color: BaseColor.inputLableColor,
      marginTop: 1,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.bold,
      letterSpacing: "0.5px",
    },
    "& .MuiFormLabel-root": {
      lineHeight: 0.4,
      fontSize: "13.9px",
    },
    "& label.Mui-focused": {
      color: BaseColor.inputMainColor,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.heavy,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, 16px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -2px) scale(0.79)",
        fontSize: "15px",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        borderColor: BaseColor.inputBorderColor,
        color: "red",
      },
      "&:hover fieldset": {
        borderWidth: "1px",
        borderColor: BaseColor.inputMainColor,
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: BaseColor.inputMainColor,
      },
      "& .MuiOutlinedInput-input": {
        padding: "14.5px 15px",
        fontFamily: FontFamily.FontFamily_1,
        color: BaseColor.inputLableColor,
        fontWeight: FontWeight.regular,
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  TextFieldStyle: {
    width: "100%",
    fontSize: 15,
    fontFamily: FontFamily.FontFamily_1,
    fontWeight: FontWeight.heavy,
    borderRadius: "8px",
    backgroundColor: BaseColor.redColor,
  },
}));
const Dropdown = (props) => {
  const {
    name,
    label,
    value,
    onBlur,
    DropdownOption,
    onChange,
    error,
    id,
    DropdownDefultValue,
    isDisabled,
  } = props;

  const [isDefult, setIsdefult] = useState("");

  return (
    <>
      <CssDropdown
        disabled={isDisabled ? true : false}
        className="fromcantrol-input"
        error={error ? true : false}
        label={label}
        id={id}
        onBlur={onBlur}
        variant="outlined"
        name={name}
        defaultValue={
          !DropdownOption || DropdownOption == undefined
            ? []
            : DropdownOption.find((item) => {
              if (item.IsSelect == true) {
                return item.ID;
              }
            })
        }
        value={value}
        onChange={onChange}
        select
      >
        {!IsEmpty(DropdownOption) &&
          DropdownOption.map((val, ind) => {
            return (
              <MenuItem key={ind} value={val.ID}>
                {name == "HandwritingStyle" ? (
                  <span
                    style={{
                      fontFamily: val.Name,
                      fontSize: "26px",
                      marginTop: '12px',
                      lineHeight: "10px",
                      display: 'flex',
                      marginTop: '8px',
                      fontWeight: "600",
                      letterSpacing: "2.5px",
                    }}

                  >
                    {val.FontName || val.FullName || val.Name}
                  </span>
                ) : (
                  val.FontName || val.FullName || val.Name
                )}
              </MenuItem>
            );
          })}
      </CssDropdown>
    </>
  );
};

export default Dropdown;
