import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './slider.css';
const SimpleSlider = props => {
	const { dots, autoplay, slidesToShow, arrows, infinite, children } = props

	let settings = {
		dots: dots,
		infinite: infinite,
		speed: 1000,
		slidesToShow: slidesToShow,
		slidesToScroll: 1,
		nav: false,
		arrows: arrows,
		// nextArrow: <div className="next-icon"><img src={Images.Next} /></div>,
		// prevArrow: <div className="previous-icon"> <img src={Images.Previous} /></div>,
		autoplay: autoplay,
		// autoplaySpeed: 7000
	};

	return (
		<div className="image-slider">
			<Slider  {...settings}>
				{children.map((obj, index) => {
					return <React.Fragment key={index}>{obj}</React.Fragment>;
				})}
			</Slider>
		</div>
	);
}
SimpleSlider.propTypes = {
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	dots: PropTypes.bool,
	arrows: PropTypes.bool,
	infinite: PropTypes.bool,
	autoplay: PropTypes.bool,
	slidesToShow: PropTypes.number,
};

SimpleSlider.defaultProps = {
	style: {},
	dots: true,
	arrows: true,
	infinite: true,
	autoplay: true,
	slidesToShow: 1,
}
export default SimpleSlider;
