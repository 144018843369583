
import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import './searchinput.css';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: BaseColor.inputLableColor,
      marginTop: 1,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.bold,
      letterSpacing: '0.5px'
    },
    '& .MuiFormLabel-root': {
      lineHeight: 0.4,
      fontSize: '13.9px'
    },
    '& label.Mui-focused': {
      color: BaseColor.mainColor,
      fontFamily: FontFamily.FontFamily_1,
      fontWeight: FontWeight.heavy
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -2px) scale(0.79)',
        fontSize: '15px'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: BaseColor.inputBorderColor,
        color: "red"
      },
      '&:hover fieldset': {
        borderWidth: '2px',
        borderColor: BaseColor.navLinkColor
      },
      '&.Mui-focused fieldset': {
        borderColor: BaseColor.mainColor
      },
      '& .MuiOutlinedInput-input': {
        padding: '5.5px 15px',
        fontFamily: FontFamily.FontFamily_1,
        color: BaseColor.inputLableColor,
        fontWeight: FontWeight.regular
      }
    }
  }
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  TextFieldStyle: {
    width: "100%",
    fontSize: 15,
    fontFamily: FontFamily.FontFamily_1,
    fontWeight: FontWeight.heavy,
    borderRadius: '8px',
    backgroundColor: BaseColor.inputBgColor
  }
}));

export default function SearchInput(props) {
  const { value, onInputChange, error, SearchSuggestion, name, label, searchDefultValue, required, editvalue } = props;

  const [suggestions, setSuggestions] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (editvalue) {
      const matchedOption = SearchSuggestion.find(option => option.ID == editvalue);
      if (matchedOption) {
        onInputChange(null, matchedOption.Name, fetchSuggestions, matchedOption.ID);
      }
    }
  }, [editvalue, SearchSuggestion]);

  const fetchSuggestions = (inputValue) => {
    const searchResult = SearchSuggestion?.filter((post) =>
      post.Name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(searchResult);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        freeSolo
        InputLabelProps={{ shrink: true }}
        className='searchfield-input'
        options={suggestions}
        getOptionLabel={(option) => option.Name || ""}
        getOptionSelected={(option, value) => option.ID === value.ID}
        inputValue={typeof value === 'string' ? value : value?.Name || ""}
        disableClearable
        value={searchDefultValue}
        onInputChange={(event, newValue) => {
          fetchSuggestions(newValue);
          onInputChange(event, newValue, fetchSuggestions);
        }}
        onChange={(event, newValue) => {
          onInputChange(event, newValue ? newValue.Name : '', fetchSuggestions, newValue ? newValue.ID : null);
        }}
        renderInput={(params) => (
          <CssTextField
            InputLabelProps={{ shrink: true }}
            error={error}
            className='fromcantrol-input'
            {...params}
            fullWidth
            name={name}
            label={required ? `${label}*` : label}
            variant="outlined"
            showClearIcon={false}
          />
        )}
      />
    </div>
  );
}


























// import React, { useState } from 'react';
// import { TextField } from '@material-ui/core';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { Autocomplete } from '@material-ui/lab';
// import './searchinput.css';
// import { BaseColor, FontFamily, FontWeight } from '../../config';

// const CssTextField = withStyles({
//   root: {
//     '& label': {
//       color: BaseColor.inputLableColor,
//       marginTop: 1,
//       fontFamily: FontFamily.FontFamily_1,
//       fontWeight: FontWeight.bold,
//       letterSpacing: '0.5px'
//     },
//     '& .MuiFormLabel-root': {
//       lineHeight: 0.4,
//       fontSize: '13.9px'
//     },
//     '& label.Mui-focused': {
//       color: BaseColor.mainColor,
//       fontFamily: FontFamily.FontFamily_1,
//       fontWeight: FontWeight.heavy
//     },
//     '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
//       transform: 'translate(14px, 16px) scale(1)',
//       '&.MuiInputLabel-shrink': {
//         transform: 'translate(14px, -2px) scale(0.79)',
//         fontSize: '15px'
//       }
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderRadius: '8px',
//         borderColor: BaseColor.inputBorderColor,
//         color: "red"
//       },
//       '&:hover fieldset': {
//         borderWidth: '2px',
//         borderColor: BaseColor.navLinkColor
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: BaseColor.mainColor
//       },
//       '& .MuiOutlinedInput-input': {
//         padding: '5.5px 15px',
//         fontFamily: FontFamily.FontFamily_1,
//         color: BaseColor.inputLableColor,
//         fontWeight: FontWeight.regular
//       }
//     }
//   }
// })(TextField);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   TextFieldStyle: {
//     width: "100%",
//     fontSize: 15,
//     fontFamily: FontFamily.FontFamily_1,
//     fontWeight: FontWeight.heavy,
//     borderRadius: '8px',
//     backgroundColor: BaseColor.inputBgColor
//   }
// }));

// export default function SearchInput(props) {
//   const { value, onInputChange, error, SearchSuggestion, name, label, searchDefultValue, required } = props;
//   const [suggestions, setSuggestions] = useState([]);
//   const classes = useStyles();

//   const fetchSuggestions = (inputValue) => {
//     const searchResult = SearchSuggestion?.filter((post) =>
//       post.Name.toLowerCase().includes(inputValue.toLowerCase())
//     );
//     setSuggestions(searchResult);
//   };

//   return (
//     <div className={classes.root}>
//       <Autocomplete
//         freeSolo
//         InputLabelProps={{ shrink: true }}
//         className='searchfield-input'
//         options={suggestions}
//         getOptionLabel={(option) => option.Name || ""}
//         getOptionSelected={(option, value) => option.ID === value.ID}
//         inputValue={typeof value === 'string' ? value : value?.Name || ""}
//         disableClearable
//         value={searchDefultValue}
//         onInputChange={(event, newValue) => {
//           fetchSuggestions(newValue);
//           onInputChange(event, newValue, fetchSuggestions);
//         }}
//         onChange={(event, newValue) => {
//           onInputChange(event, newValue ? newValue.Name : '', fetchSuggestions, newValue ? newValue.ID : null);
//         }}
//         renderInput={(params) => (
//           <CssTextField
//             InputLabelProps={{ shrink: true }}
//             error={error}
//             className='fromcantrol-input'
//             {...params}
//             fullWidth
//             name={name}
//             label={required ? `${label}*` : label}
//             variant="outlined"
//             showClearIcon={false}
//           />
//         )}
//       />
//     </div>
//   );
// }
