import React from 'react';
import './BulkUploadInstructions.css';

const BulkUploadInstructions = () => {
    return (
        <div className="bulk-upload-container">
            <header className="bulk-upload-header">
                <h1>INSTRUCTIONS FOR BULK UPLOAD</h1>
            </header>
            <section className="bulk-upload-instructions">
                <p>We recommend a maximum of 1,000 records per upload.</p>
                <p>Bulk upload can be used to add addresses to your address book or for bulk card orders.</p>
                <ul>
                    <li>Download the bulk upload template (csv)</li>
                    <li>Complete a row for each address you wish to add</li>
                    <li>Upload your completed file in .csv format</li>
                </ul>
            </section>
            <table className="bulk-upload-table">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Type*</td>
                        <td>"Sender" or "Recipient"</td>
                    </tr>
                    <tr>
                        <td>First Name*</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>Last Name*</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>Address*</td>
                        <td>Numbers + Text</td>
                    </tr>
                    <tr>
                        <td>Address 2</td>
                        <td>Numbers + Text</td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>State/Province*</td>
                        <td>Please use full names for US States (E.g., California, not CA)</td>
                    </tr>
                    <tr>
                        <td>Postal Code*</td>
                        <td>For US addresses, 5 digits are required. For non-US addresses, any postal code may be used.</td>
                    </tr>
                    <tr>
                        <td>Country*</td>
                        <td>If no country is provided, USA is assumed. USA, U.S., U.S.A, United States, and United States of America are all acceptable for US addresses. US postage rates apply for US addresses. Non-US postage rates apply for all other countries.</td>
                    </tr>
                    {/* <tr>
                        <td>Phone</td>
                        <td>E.g., (801) 444-4444 or 999.222.2222</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>name@company.com</td>
                    </tr> */}
                    <tr>
                        <td>Company</td>
                        <td>Text</td>
                    </tr>
                    <tr>
                        <td>Anniversary</td>
                        <td>May be used for anniversary card automation. Format as MM/DD/YYYY – e.g., 05/20/1990</td>
                    </tr>
                    <tr>
                        <td>Custom 1</td>
                        <td rowSpan="3" className="centered-text">
                            The custom fields are used only in a bulk order and will not be saved to the address book.
                            They can be used for special dynamic inserts into the message.
                        </td>
                    </tr>
                    <tr>
                        <td>Custom 2</td>
                    </tr>
                    <tr>
                        <td>Custom 3</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default BulkUploadInstructions;
