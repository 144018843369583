import React, { createContext, useState } from 'react';

const MyContext = createContext();

const MyProvider = ({ children }) => {

    const [cartCount, setCartCount] = useState("0");

    const updateCartCount = (count) => {
        setCartCount(count);
    };

    return (
        <MyContext.Provider value={{ cartCount, updateCartCount }}>
            {children}
        </MyContext.Provider>
    );
};

export { MyContext, MyProvider };