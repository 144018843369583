/*eslint-disable eqeqeq*/
import { Component } from 'react';

// import authService from "../services/auth-service";
/**
 * HTTP method types
 */
export const HTTPMethod = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
}

/**
 * Default HTTP header content types
 */
export const HTTPHeaderContentType = {
    json: "application/json",
    formURLEncoded: "application/x-www-form-urlencoded",
}

/**
 * A network utility class that performs all the necessary underlying HTTP piping and server authentication work for all HTTP request
 */
export class API extends Component {

    /**
     * Creates a promise that wraps an HTTP request configured with given parameters.
     * 
     * @param {String} method HTTP method
     * @param {String} url The target HTTP request URL
     * @param {props} body The body of the HTTP request
     */
    request(method, url, body) {
        return new Promise((resolve, reject) => {

            let headers = method.indexOf("token") == -1 ? this.requestHeaders() : this.requestTokenHeaders()
            fetch(url, { method: method, headers: headers, body: body })
                .then((response) => {
                    if (response.headers.get('content-type').indexOf('image') != -1 || response.headers.get('content-type').indexOf('html') != -1 || response.headers.get('content-type').indexOf('x-zip-compressed') != -1 || response.headers.get('content-type').indexOf('application/pdf') != -1) {
                        return response
                    } else {
                        return response.json()
                    }
                })
                .then((responseJSON) => {
                    if (responseJSON.Message == "Authorization has been denied for this request.") {
                        // authService.removeAuthData();
                        localStorage.clear()
                        alert("Authorization has been denied for this request.")
                        window.location.replace('/');
                    } else {
                        resolve(responseJSON)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    request1(method, url) {
        return new Promise((resolve, reject) => {
            let headers = method.indexOf("token") == -1 ? this.requestHeaders() : this.requestTokenHeaders()
            fetch(url, { method: method, headers: headers })
                .then((response) => { return response.json() })
                .then((responseJSON) => { resolve(responseJSON) })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    requestTokenHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    /**
     * Builds and returns a prop containing HTTP Header key-value pairs with the appropriate values populated.
     */
    requestHeaders() {
        return {
            "Accept": HTTPHeaderContentType.json,
            "Content-Type": HTTPHeaderContentType.json,
            "Authorization": "Bearer " + localStorage.getItem('Access_Token')

            // "Authorization": "Bearer " + "Ypo5NJ1Hqs5Y3_oRyAemzFR2j8CTBxESF3Cyeld_8IrLhuhqJLLKaswNqn7okwjrF-V5BrpL8pg_UEENoJkyiuIjxhpwWHdzTYZBIKdZ-DRSVYn2kSCsCrprPDuNhqSujpBBu1QzfcUkjfnL37VX8xSSS7GVMPRC7Z0pBG3e1DEO_uye9hjmk0uFRsOoO3h4nJwj-Wt0CWOREa3HkfUKKIQHFJx6wkwiHpY3rc-U5n4y4x7D5OxW8mk5iTVbJeiwJTi74PJhPkA0Dm59wjjvOPt-1Pa73OlTy0-9kgbclBouYodlvT_8noJnLqMlRfWjxtbxu9oLo1-7lO5IlpH7HJZUUScfstqe_SEcogsls15ryZI2SmlcuMGWKWP8r8RARin8s62N0bzWdWFjsyuN3ogHhRxCeBEQnoXwIo6juUwhNbXwgYRiPq5YiyCI3vrRS6SmaQE-agH2Rli--ClS6-elU3fi8aUYlDuVjoHTvpOOqXYK18VMWC05WQ8BywT07owFhCZAevlhUD11PGldexd0Bt1BX7yohRDQRJbiCf064UrepL7MVgTVJ-hwP1eGHlXjpV2-PXlA2VUONsJS7KKqUGIbymTNDRTqhXmSN3nVOEMTWgXWi3ToUwTqOaiBAGG5YDGiUO5bl-OMHc86Oi2Qnx3hqWP8-zTAkHkh5kOp2AaFe9sjb505ZvZJo6gc_W15pLxl5psjikbmMjNNcEPYd6l8h_kVlDQ0BJyWMZv83BMdrU-BUZVUs2F_4zbsFFM2RlKdMhNOhySm9KCILVP-z7tNsws8oOAxXcolcRuGidd6"
        }
    }
}

API.shared = new API()