import * as React from 'react';
import { Box, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import CommonButton from "../../components/Button/Button";

export default function HorizontalLinearStepper(props) {
    const { activeStep, IsLoading, steps, SubmitAllow, handleBack, children, handleNext } = props
    return (
        <Box style={{ width: '100%', margin: '3px 0px', padding: 10 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                {children}
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }} >
                    {activeStep != 0 && <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep === steps.length - 1 ? SubmitAllow == true && <Button onClick={handleNext} IsLoading={IsLoading} type='submit' color="primary">{'Save'}</Button>
                        :
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    }

                </Box>
            </React.Fragment>

        </Box>
    );
}
