import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { URLs } from '../../API/URLs';
import { TextField } from "../../components";

const PlacesAutocompleteTextField = (props) => {
  const { label, style, name, required, variant, error, value, onChange, selectedPlaced, disabled } = props;
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${URLs.googlekey}&libraries=places`;
      script.onload = () => {
        setGoogleLoaded(true);
        initAutocomplete();
      };
      script.onerror = () => {
        console.error('Failed to load Google Maps API script');
      };
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      setGoogleLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (googleLoaded) {
      initAutocomplete();
    }
  }, [googleLoaded]);

  function initAutocomplete() {
    if (inputRef.current && window.google && window.google.maps && window.google.maps.places) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ['address'], componentRestrictions: { country: 'us' } }
      );

      autocompleteRef.current.addListener('place_changed', onPlaceChanged);
    } else {
      console.error('Google Maps API or Places library is not loaded properly');
    }
  }

  function onPlaceChanged() {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const data = getCityAndPostalCode(place);
      selectedPlaced(data);
    }
  }

  function getCityAndPostalCode(place) {
    let city = '';
    let postalCode = '';
    let addressLine1 = '';
    let addressLine2 = '';
    let country = '';
    let state = '';
    let latitude = '';
    let longitude = '';
    let fullAddress = '';

    if (place.address_components) {
      place.address_components.forEach(component => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (component.types.includes("street_number") || component.types.includes("route")) {
          addressLine1 += component.long_name + " ";
        } else if (component.types.includes("subpremise")) {
          addressLine2 = component.long_name;
        }
      });

      latitude = place.geometry?.location?.lat() || '';
      longitude = place.geometry?.location?.lng() || '';
      fullAddress = place.formatted_address || '';
    }

    return { city, postalCode, fullAddress, addressLine1, addressLine2, state, country, latitude, longitude };
  }

  return (
    <TextField
      style={style}
      disabled={disabled}
      label={label}
      name={name}
      value={value}
      variant={variant}
      onChange={onChange}
      required={required}
      error={error}
      inputRef={inputRef}
    />
  );
};

PlacesAutocompleteTextField.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  selectedPlaced: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

PlacesAutocompleteTextField.defaultProps = {
  style: {},
  value: '',
  name: '',
  variant: 'outlined',
  onChange: () => { },
  selectedPlaced: () => { },
  required: false,
  error: false,
  disabled: false,
};

export default PlacesAutocompleteTextField;
