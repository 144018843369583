import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControl, Typography } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';
// import CommonStyle from "../../Camponets/config/CommonStyle";
const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    display: 'block',
    margin: `${theme.spacing(3)}px 0`,
  },
  field: {
    margin: `${theme.spacing(3)}px 5px`,
  },
  formControl: {
    margin: theme.spacing(1),

  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  switchBase: {
    color: green[50],
    '&$checked': {
      color: green[500],
      '& + $bar': {
        backgroundColor: green[500],
      },
    },
  },
  bar: {},
  checked: {},
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
});
const PurpleSwitch = withStyles({
  switchBase: {

    '&$checked': {
      color: BaseColor.mainColor, // Color when switch is active
    },
    '&$checked + $track': {
      backgroundColor: BaseColor.mainColor, // Background color when switch is active
    },
  },
  checked: {},
  track: {},
})(Switch);

const PurpleFormControlLabel = withStyles({
  root: {
    color: "gray", // Default color
    fontFamily: FontFamily.FontFamily_1,
    '&$checked': {
      color: BaseColor.navLinkColor, // Color when switch is active
    },
  },
  checked: {},
})(FormControlLabel);



function CommonSwitch(props) {
  const { onChange, checked, name, label, value, defaultValue, classes, helper } = props;
  return (
    <>
      <FormControl aria-describedby="name-helper-text">
        <FormControlLabel
         
          className={checked}
          control={(
            <PurpleSwitch
              name={name}
              checked={checked}
              onChange={onChange}
              defaultChecked={defaultValue}
              value={value}
              color="primary"
            />
          )}
          label={
            <Typography
              variant="body1"
              style={{
                fontFamily: FontFamily.FontFamily_1, // Change font family
              
                color: value ? BaseColor.mainColor : undefined,
                fontSize:'16px'
              }}
            >
              {label}
            </Typography>
          }
        />
        {helper && <FormHelperText id="name-helper-text">{helper}</FormHelperText>}
      </FormControl>
    </>
  );

}

CommonSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CommonSwitch;